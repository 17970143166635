import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import CallToAction from '../../components/CallToAction/CallToAction'
import styled from 'styled-components'
import AdvertisingClients from '../../components/AdvertisingClients/AdvertisingClients'
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper'


const Container = styled.div`
  margin-top: 160px;

  @media screen and (min-width: 1080px) {
    margin-top: 80px;
  }
`

const FilmographyPage = ({ data }) => {

  return (
    <>
      <Seo title="Advertising" />
      <Layout>
        <Container>
          <h1>Advertising</h1>
        </Container>

        <LoadingWrapper data={data}/>
        
        <AdvertisingClients />

        <CallToAction header="Work with me" text="Work with me" to="/contact"/>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allMdx(filter: { fileAbsolutePath: { regex: "/advertising/" } }, sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date
          title
          featured_image {
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`

export default FilmographyPage
